.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #f5f5f5;
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
	// transition: all 0.4s ease;
}

.header-desktop {
	height: 130px;
	transition: height 0.2s ease;
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
	}
}

.logo {
	position: absolute;
	#{$left}: 0;
	top: 0;
	height: 100%;
	transition: all 0.2s ease;
	& a {
		display: block;
		padding: 0 15px;
		background-color: #f5f5f5;
		transition: all 0.2s ease;
	}
	& img {
		height: 104px;
		margin-top: 12px;
		transition: all 0.2s ease;
		@include respond-to('large') {
			height: 90px;
			margin-top: 20px;
		}
	}
}

.sub-nav {
	height: 60px;
	border-bottom: 1px solid #e1e1e1;
	transition: all 0.2s ease;
	& > ul {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		list-style: none;
		transition: all 0.2s ease;
		& > li {
			margin: 0 5px;
			display: inline-block;
			vertical-align: middle;
			color: #777777;
			&:first-child {
				margin-#{$right}: 0;
			}
			&.sub-phone {
				margin-#{$right}: 57px;
				line-height: 1;
				vertical-align: bottom;
				& span {
					display: inline-block;
					margin-#{$left}: 8px;
					vertical-align: bottom;
					line-height: 1;
					font-size: 18px;
					color: #000;
				}
				& a {
					display: inline-block;
					font-size: 22px;
					font-weight: 800;
					color: $accent;
					line-height: 1;
					@include link_no-hover(#477f29);
				}
				& i {
					display: inline-block;
					margin-#{$right}: 8px;
					color: #000;
				}
			}
			& > a {
				display: inline-block;
				vertical-align: middle;
				color: #777777;
				& i {
					font-size: 20px;
					vertical-align: middle;
				}
				& i.fa-facebook-f {
					font-size: 18px;
					font-weight: 700;
					color: #000;
				}
			}
			& i.fa-search {
				font-size: 20px;
				color: #477f29;
			}
		}
	}
}

.find-last {
	display: inline-block;
	height: 100%;
	vertical-align: top;
	margin-#{$right}: 50px;
	transition: all 0.2s ease;
}

.search {
	position: relative;
	width: 245px;
	// @include respond-to('large') {
	// 	width: 170px;
	// }
	& input {
		width: 100%;
		border: none;
		border-bottom: 1px solid #707070;
		padding: side-values(23px 0 11px 25px);
		font-size: 18px;
		font-weight: 300;
		text-indent: 5px;
		background-color: transparent;
		color: #949494;
		transition: all 0.2s ease;
		&::placeholder {
			font-size: 18px;
			font-weight: 300;
			color: #949494;
		}
	}
	& button {
		position: absolute;
		top: 24px;
		#{$left}: 0;
		border: none;
		background: none;
		transition: all 0.2s ease;
		& i {
			color: #000;
		}
	}
}

.main-navi {
	display: block;
	padding: 0;
	transition: padding 0.2s ease;
	& > ul {
		display: inline-block;
		vertical-align: top;
		list-style: none;
		padding: 0;
		margin: 0;
		padding-top: 12px;
		transition: all 0.2s ease;
		@include respond-to('1100') {
			padding-top: 17px;
		}
		& > li {
			float: right;
			margin-#{$left}: 2px;
			position: relative;
			& > a {
				display: block;
				padding: 4px 11px 5px;
				font-size: 20px;
				font-weight: 400;
				color: #000;
				border: 1px solid transparent;
				transition: all 0.2s ease;
				@include respond-to('1100') {
					padding: 4px 8px 5px;
					font-size: 18px;
				}
				&:hover {
					text-decoration: none;
					border: 1px solid $accent;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border: 1px solid $accent;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 7px;
		float: $left;
		& img {
			width: 150px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 19px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.mobile-menu-btn {
	margin-top: 20px;
}

.main-header.scrolling {
	.header-desktop {
		height: 111px;
	}
	.sub-nav {
		height: 53px;
		padding: 0;
		& ul {
			padding: 11px 0 10px;
		}
	}
	.find-last {
		padding-top: 10px;
		& .search input {
			padding: side-values(9px 0 8px 25px);
		}
		& .search button {
			top: 8px;
		}
	}
	.main-navi {
		& ul {
			padding-top: 10px;
		}
	}
	.logo {
		& a {}
		& img {
			height: 86px;
			max-width: 226px;
			margin-top: 12px;
		}
	}
	.header-mobile {}
}