.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	max-height: 800px;
	overflow: hidden;
	@include respond-to('medium') {
		max-height: unset;
		height: auto;
	}
	// @include respond-to('huge') {
	// 	height: 650px;
	// }
	// @include respond-to('large') {
	// 	height: 450px;
	// }
	@include respond-to('small') {
		height: 450px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	height: 100vh;
	max-height: 800px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	// @include respond-to('medium') {
	// 	background-position: left center;
	// }
	// @include respond-to('extra-small') {
	// 	background-position: left -150px center;
	// }
	@include respond-to('small') {
		height: 450px;
	}
	& .section-limit {
		// position: relative;
		height: 100vh;
		max-height: 800px;
		@include respond-to('small') {
			height: 450px;
		}
	}
}

.shadow-text {
	position: absolute;
	top: 53%;
	max-width: 450px;
	width: 100%;
	@include respond-to('medium') {
		top: 45%;
	}
	&__title,
	&__subtitle {
		color: #fff;
		line-height: 0.9;
	}
	&__title {
		font-size: 50px;
		font-weight: 800;
		@include respond-to('small') {
			// font-size: 45px;
		}
		@include respond-to('extra-small') {
			font-size: 40px;
		}
	}
	&__subtitle {
		font-size: 40px;
		font-weight: 400;
		@include respond-to('extra-small') {
			font-size: 30px;
		}
	}
}