footer {
	width: 100%;
	position: relative;
	background-color: #f9f9f9;
	z-index: 1;
	padding: 40px 0 10px;
	box-shadow: 0 -5px 45px 0 rgba(0, 0, 0, 0.15);
	@include respond-to('medium') {
		padding: 25px 0 10px;
	}
}

.ftr-logo {
	text-align: center;
	margin-bottom: 10px;
	& a {
		& img {
			// max-width: 155px;
		}
	}
}

.ftr-contact {
	text-align: center;
	padding: 20px 0 15px;
	& ul {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style: none;
		@include respond-to('extra-small') {
			max-width: 290px;
		}
		& li {
			float: $right;
			margin-#{$left}: 35px;
			&:last-child {
				margin-#{$left}: 0;
			}
			@include respond-to('medium') {
				width: 50%;
				margin-#{$left}: 0;
				text-align: right;
			}
			@include respond-to('extra-small') {
				width: 100%;
			}
			& a {
				font-size: 18px;
				font-weight: 400;
				@include link_no-hover(#000);
				& span {
					display: inline-block;
					& i {
						margin-#{$left}: 14px;
						font-size: 22px;
						color: #702032;
					}
				}
			}
			& a[href="tel:"] {
				& span {
					direction: ltr;
				}
			}
		}
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #dedede;
	margin-top: 0;
	margin-bottom: 10px;
}
