.breadcrumb {
	position: relative;
	z-index: 1;
	background-color: transparent;
	margin-bottom: 0;
	padding: 14px 0;
	@include respond-to('small') {
		display: none;
	}
	& li {
		font-size: 16px;
		&::before {
			color: #000;
		}
		& a {
			text-decoration: none;
			color: #000;
			font-size: 16px;
			font-weight: 400;
		}
	}
	& .active {
		color: #86c430;
	}
}
