.banner-nav {
	width: 100%;
	z-index: 1;
}

.wrapper-all-content {
	position: relative;
	width: 100%;
}

.sec-padd {
	padding: 70px 0 60px;
	@include respond-to('1100') {
		padding: 35px 0 40px;
	}
	@include respond-to('small') {
		padding: 25px 0 35px;
	}
}

.slick-next:before, .slick-prev:before{
  color: #000;
}

[dir=rtl] .slick-prev{
  right: -25px;
}

[dir=rtl] .slick-next{
  left: -25px;
}

.hp-about {
	position: relative;
	overflow: hidden;
	z-index: 1;
	text-align: center;
	// padding: 70px 0 60px;
	@include respond-to('1100') {
		// padding: 50px 0 30px;
	}
	@include respond-to('small') {
		// padding: 25px 0;
	}
	&__title {
		margin-bottom: 18px;
		@include respond-to('small') {
			margin-bottom: 15px;
		}
	}
	&__link {
		margin-top: 15px;
		@include respond-to('extra-small') {
			margin-top: 10px;
		}
	}
	&__desc {
		max-width: 1100px;
		width: 100%;
		margin: 0 auto;
		@include respond-to('1550') {
			max-width: 950px;
		}
	}
	& .section-limit {
		position: relative;
		z-index: 2;
	}
}

.hp-top-decor {
	position: absolute;
	left: 0;
	bottom: 0;
	max-width: 531px;
	min-width: 445px;
	width: 27vw;
	@include respond-to('1750') {
		left: -80px;
	}
	@include respond-to('1550') {
		left: -120px;
	}
	@include respond-to('huge') {
		opacity: 0.7;
		min-width: 355px;
	}
	@include respond-to('large') {
		display: none;
	}
}

// .section-decor {
// 	pointer-events: none;
// 	position: absolute;
// 	top: -57px;
// 	left: 0;
// 	right: 0;
// 	margin: 0 auto;
// 	display: block;
// 	width: 1475px;

// 	&_2 {
// 		width: 1450px;
// 	}
// }

.section-devider {
	position: relative;
	height: 1px;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	background-color: $accent;
	&::after {
		content: url(../images/sec-decor.png);
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 1;
		padding: 0 15px;
		transform: translate(-50%, -50%);
		background-color: #fff;
	}
	&::before {
		content: url(../images/sec-decor-leave.png);
		position: absolute;
		left: -144px;
		top: -41px;
		z-index: 1;
		@include respond-to('larger') {
			display: none;
		}
	}
	&_2 {
		&::before {
			content: url(../images/sec-decor-leave2.png);
			right: -119px;
			left: auto;
			top: -61px;
		}
	}
}

.hp-services-sec {
	position: relative;
	background-color: #fff;
	&__title {
		margin-bottom: 15px;
	}
}

.services {
	padding-top: 15px;
	margin: 0 -38px;
	font-size: 0;
	text-align: center;
	@include respond-to('large') {
		margin: 0 -20px;
	}
	@include respond-to('1100') {
		margin: 0 -10px;
	}
	&__item {
		display: inline-block;
		width: 33.33%;
		height: 391px;
		padding: 38px;
		vertical-align: top;
		@include respond-to('large') {
			height: 351px;
			padding: 20px;
		}
		@include respond-to('1100') {
			padding: 10px;
			height: 300px;
		}
		@include respond-to('medium') {
			padding: 5px;
		}
		@include respond-to('small') {
			width: 50%;
			// margin-bottom: 20px;
		}
		@include respond-to('extra-small') {
			width: 100%;
			height: 250px;
		}
	}
}

.gradient-back {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-image: url(../images/grad-back.png);
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
}

.homepage-box {
	overflow: hidden;
	display: block;
	position: relative;
	// width: 205px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include link_no-hover(#000);
	&:hover {
		& .gradient-back {
			transform: scale(1.5);
		}
	}
	&__info {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		width: 100%;
		padding: 0 15px;
	}
	&__info-title {
		margin-bottom: 18px;
		line-height: 1;
		font-size: $font-size-large;
		font-weight: 800;
		color: $text;
		@include respond-to('1100') {
			margin-bottom: 15px;
			font-size: 22px;
		}
	}
	&__info-desc {
		font-size: $font-size-base;
		font-weight: 600;
		color: $text;
		margin-bottom: 35px;
		@include respond-to('1100') {
			margin-bottom: 15px;
			font-size: 18px;
		}
	}
	&__info-link {
		@include respond-to('1100') {
			font-size: 18px;
		}
	}
}

.hp-branch-sec {
	position: relative;
	background-color: #ffff;
}

.branch {
	position: relative;
	z-index: 1;
	padding-top: 45px;
	padding-bottom: 20px;
	margin: 0 -13px;
	font-size: 0;
	text-align: center;
	@include respond-to('larger') {
		padding-top: 25px;
	}
	@include respond-to('large') {
		padding-top: 5px;
		padding-bottom: 15px;
	}
	@include respond-to('medium') {
		margin: 0 -5px;
	}
	&__item {
		display: inline-block;
		width: 25%;
		height: 336px;
		vertical-align: top;
		padding: 13px;
		@include respond-to('medium') {
			padding: 13px 5px;
		}
		@include respond-to('small') {
			width: 50%;
			// margin-bottom: 20px;
		}
		@include respond-to('mobile-l') {
			width: 100%;
		}
	}
	& .slick-dots {
		bottom: -20px;
		& li {
			margin: 0;
			padding: 0;
			height: 22px;
			& button {
				padding: 0;
				&::before {
					content: "";
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 8px;
					height: 8px;
					border: 1px solid $accent;
					border-radius: 50%;
					opacity: 1;
				}
			}
		}
		& li.slick-active {
			& button {
				&::before {
					border: 1px solid $accent;
					background-color: $accent;
					opacity: 1;
				}
			}
		}
	}
}

.branch-box {
	overflow: hidden;
	display: block;
	max-width: 282px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	border: 1px solid #d8d8d8;
	background-color: #fff;
	padding: 9px;
	@include link_no-hover(#000);
  box-shadow: 0 5px 15px 0 rgba($color: #000000, $alpha: 0.05);
 
	&__img {
		width: 100%;
		height: 200px;
		background-position: center;
		background-size: cover;
    background-repeat: no-repeat;
    position: relative;
		@include respond-to('medium') {
			height: 190px;
		}
  }
  &__googlemap{
    position: absolute;
    top: 5px;
    #{$right}: 5px;
    margin: 0 !important;
    & img{
      margin: 0 !important;
    }
  }
  &__wazemap{
    position: absolute;
    top: 5px;
    #{$right}: 55px;
    & img{
      margin: 0 !important;
    }
  }
  &__phone{
    position: absolute;
    top: 5px;
    #{$right}: 105px;
    & img{
      margin: 0 !important;
    }
  }
	&__info {
		padding: 10px 0 10px;
	}
	&__info-title {
		overflow: hidden;
		max-height: 40px;
		margin-bottom: 10px;
		font-size: 20px;
		font-weight: 800;
		text-align: center;
		line-height: 1;
	}
	&__info-desc {
		overflow: hidden;
		max-height: 55px;
		max-width: 90%;
		margin: 0 auto;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		line-height: 1.2;
	}
}

.hp-decor {
	pointer-events: none;
	position: absolute;
	bottom: -95px;
	left: -50px;
	@include respond-to('huge') {
		bottom: -45px;
	}
	@include respond-to('small') {
		display: none;
	}
}