.wrap-content {
	position: relative;
	padding: 0;
	&_cont {
		padding: 20px 0 35px;
		min-height: 500px;
		@include respond-to('small') {
			padding: 30px 0 10px;
		}
		@include respond-to('extra-small') {
			padding: 20px 0 10px;
		}
	}
	& .section-limit {
		position: relative;
		z-index: 1;
	}
}

.cont-decor-left {
	position: absolute;
	left: -40px;
	bottom: 0;
	width: 30vw;
	// z-index: auto;
	@include respond-to('1550') {
		left: -80px;
		width: 33vw;
	}
	@include respond-to('larger') {
		max-width: 450px;
		// opacity: 0.7;
	}
	@include respond-to('1100') {
		min-width: 370px;
	}
	@include respond-to('medium') {
		// display: none;
		opacity: 0.7;
	}
	@include respond-to('extra-small') {
		display: none;
	}
}

.cont-decor-right {
	position: absolute;
	right: -45px;
	top: -175px;
	// width: 30vw;
	@include respond-to('1750') {
		// opacity: 0.7;
		right: -65px;
	}
	@include respond-to('huge') {
		width: 150px;
	}
	@include respond-to('larger') {
		// max-width: 200px;
		// right: -10px;
	}
	@include respond-to('small') {
		display: none;
	}
}

.title {
	margin: 0;
	text-align: center;
	color: $title;
	font-size: $font-size-large;
	font-weight: 800;
	line-height: 1;
	@include respond-to('small') {
		font-size: $font-size-medium;
	}
	@include respond-to('extra-small') {
		font-size: 22px;
	}
	&_tar {
		text-align: $right;
	}
	&_mb {
		margin-bottom: 35px;
		@include respond-to('medium') {
			margin-bottom: 25px;
		}
		@include respond-to('small') {
			margin-bottom: 15px;
		}
	}
}

.description {
	max-width: 1150px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	& p {
		font-size: $font-size-base;
		font-weight: 400;
		color: $text;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
}

.link {
	display: inline-block;
	padding-bottom: 8px;
	border-bottom: 2px solid $accent;
	font-size: 20px;
	font-weight: 800;
	// color: #000;
	@include link_no-hover(#000);
	@include respond-to('small') {
		font-size: 18px;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	&_content {
		max-width: 900px;
		// padding-#{$right}: 35px;
		width: 100%;
		@include respond-to('medium') {
			// padding-#{$right}: 0;
		}
	}
	h2,
	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 1.2;
		color: #000;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 22px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p,
	li {
		font-size: 18px;
		font-weight: 400;
		color: $text;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}